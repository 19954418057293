import React from "react";

import { UserButton, SignedIn, SignedOut } from "@site/src/auth";

import {
  SignUpButton as ClerkSignUpButton,
  SignInButton as ClerkSignInButton,
} from "@clerk/clerk-react";

export default function LoginNavbarItem() {
  const component = "shift-intro";

  return (
    <>
      <div
        className="navbar__item"
        style={{
          width: "1px",
          background: "#0000001a",
          height: "20px",
          margin: "0 10px",
          padding: "0",
        }}
      ></div>
      <SignedOut>
        <ClerkSignInButton>
          <a style={{ cursor: "pointer" }} className="navbar__link">
            Login
          </a>
        </ClerkSignInButton>
        <ClerkSignUpButton>
          <a
            style={{
              height: "30px",
              padding: ".25rem 1.4rem",
              textTransform: "capitalize",
              fontWeight: "100",

              fontSize: "var(--global-font-size-medium)",
            }}
            className="
				shift-button
				shift-button__size--default
				shift-button__color--default
				shift-button__shift-intro
			"
          >
            Register
          </a>
        </ClerkSignUpButton>
      </SignedOut>
      <SignedIn>
        <div style={{ marginLeft: "20px", marginTop: "8px" }}>
          <UserButton />
        </div>
      </SignedIn>
    </>
  );
}
