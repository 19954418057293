import React, { useEffect } from 'react';
import Layout from '@theme-original/Layout';
import type LayoutType from '@theme/Layout';
import type {WrapperProps} from '@docusaurus/types';
import { initialize } from '@site/src/instrumentation'
import useDocusaurusContext from '@docusaurus/useDocusaurusContext';
import {CustomFields} from '@site/docusaurus.config';
import {AuthProvider} from '@site/src/auth';

type Props = WrapperProps<typeof LayoutType>;

export default function LayoutWrapper(props: Props): JSX.Element {
  const { siteConfig } = useDocusaurusContext();

  useEffect(() => {
    const { sentryDsn, sentryEnvironment } = siteConfig.customFields as CustomFields

    initialize(sentryDsn, sentryEnvironment)
  }, siteConfig.customFields)


  return (
    <AuthProvider>
      <Layout {...props} />
    </AuthProvider>
  );
}
